import React, {useEffect, useState} from "react";

import api from "../../utils/api";

import {useCookies} from "react-cookie";

import Layout from "../../components/Layout";
import Section from "../../components/Section";
import Promo from "../../components/Promo";
import Card from "../../components/Card";

const Resources = (props) => {
	const [resources, setResources] = useState([]);

	const [cookies] = useCookies(["token", "id"]);
	const [token] = useState(cookies["token"]);
	const [idUser] = useState(cookies["id"]);

	useEffect(() => {
		api.getResources(token, {id: idUser}).then((res) => {
			setResources(res.files);
		});
	}, [idUser, token]);

	return (
		<Layout location={props.location}>
			<Section title={"Kasulikud lisamaterjalid"}>
				<ul className={"mt-11 md:w-11/12"}>
					{resources.map((item) => {
						return (
							<Card
								guidePage={false}
								key={item.displayName}
								title={item.displayName}
								link={item.filePath}
							/>
						);
					})}
				</ul>
			</Section>
			<Promo/>
		</Layout>
	);
};

export default Resources;
